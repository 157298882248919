import { Component, ViewChild } from '@angular/core';
import { LayoutService } from './layout.service';
import { onMainContentChange, onSideNavChange } from './sidenav/animation';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
/** This component determines the overall display */
@Component({
  selector: 'teneo-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [onMainContentChange, onSideNavChange]
})
export class LayoutComponent {
  /**
   * The viewchild property for the HTML element containing the drawer component
   */
  @ViewChild('drawer') sideDrawer: MatDrawer;

  /**
   * Boolean value checking state change for sidenav
   */
  public sideNavChange: boolean;

  /**
   * The constructor
   * @param ls The LayoutService managing event emitters etc.
   * @param router Router service for navigation
   */
  constructor(public ls: LayoutService, public router: Router) {
    this.ls.sideNavState$.subscribe(res => {
      this.sideNavChange = res;
    });
  }

  /**
   * Toggles Sidenav expanded state
   */
  sideNavButtonClicked() {
    this.ls.expanded = !this.ls.expanded;
    this.ls.sideNavState$.next(!this.sideNavChange);
  }

  /**
   * Method for determining loading state
   */
  // getLoading() {
  //   return this.loadingService.isBusy;
  // }
}
