
export class Navigation {
    public navItems: any[];
    loginRoute = '/login';
    isAdmin = JSON.parse(localStorage.getItem('isAdmin'));
    constructor(
    ) {
        if(this.isAdmin) {
            this.navItems = [
                { name: 'Dashboard', link: '/dashboard', icon: 'dashboard' },
                { name: 'Users', link: '/users', icon: 'face' },
                { name: 'Organizations', link: '/organizations', icon: 'group' },
                { name: 'Templates', link: '/template', icon: 'assignment'},
                { name: 'Job Descriptions', link: '/job-descriptions', icon: 'work'},
                { name: 'Pricing', link: '/pricing', icon: 'attach_money' },
                { name: 'Lookup Data', link: '/lookup-data', icon: 'list_alt' },
                { name: 'Webinar', link: '/webinar', icon: 'meeting_room' },
                { name: 'Log out', link: '/login', icon: 'logout' }
            ];
        }else {
            this.navItems = [
                { name: 'Dashboard', link: '/dashboard', icon: 'dashboard' },
                { name: 'Users', link: '/users', icon: 'face' },
                { name: 'Organizations', link: '/organizations', icon: 'group' },
                { name: 'Log out', link: '/login', icon: 'logout' }
            ];
        }

    }
}
