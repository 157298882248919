const ASSESSMENT_BASE_PATH = "https://nrfskq5yqzga3jpqucuuo732fm.appsync-api.eu-west-1.amazonaws.com/graphql";
const JOB_SERVICE_BASE_PATH = "https://r6xqwhf6u5cftfjhh55mikknfi.appsync-api.eu-west-1.amazonaws.com/graphql";
const ORGANIZATION_BASE_PATH = "https://6keexw2u3ba6hk6lljqlo4rblq.appsync-api.eu-west-1.amazonaws.com/graphql";
const DASHBOARD_BASE_PATH = "https://5flofo9fzb.execute-api.eu-west-1.amazonaws.com/prod";
const PRICING_BASE_PATH = "https://gsjgt7b4ynctpj4q4wx6cjmg7e.appsync-api.eu-west-1.amazonaws.com/graphql";
const SIGNUP_BASE_PATH = "https://gsjgt7b4ynctpj4q4wx6cjmg7e.appsync-api.eu-west-1.amazonaws.com/graphql";

export const environment = {
  production: false,

  api: {
    assessment: ASSESSMENT_BASE_PATH,
    jobService: JOB_SERVICE_BASE_PATH,
    dashboardService: DASHBOARD_BASE_PATH,
    organizationService: ORGANIZATION_BASE_PATH,
    pricingService: PRICING_BASE_PATH,
    signUpService: SIGNUP_BASE_PATH
  }

};


