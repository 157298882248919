import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild, Router, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { LookupDataComponent } from '../lookup-data/lookup-data.component';
/**
 * Authguard component for checking route authentication
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  /**
   * The constructor
   * @param router Router service for navigation
   * @param authService Authentication service for http requests
   */
  constructor(private readonly router: Router) { }

  /**
   * Determines whether the next route can be activated
   * @param next The next route in the queue
   * @param state The current state of the router
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let url: string = state.url;

    return this.checkLogin(url);
  }

  /**
   * Determines whether a child route can be activated
   * @param route The child route
   * @param state The current state of the router
   */
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(route, state);
  }
  /**
   * Determines logged in state of user
   * @param url The next url in the queue
   */
  checkLogin(url: string): boolean {
    if (sessionStorage.getItem('loggedIn')) {
      let isLogged;
      if(sessionStorage.getItem('loggedIn') === "yes") {
        isLogged = true;
      }
      if (isLogged) { return true; }
    } else {
      this.router.navigate(['/login']);
      localStorage.clear();
      sessionStorage.clear();
      return false;
    }
  }
}
