import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LookupEditCanDeactivateGuard } from './auth/auth.deactivate';
import { AuthGuard } from './auth/auth.guard';
import { LayoutComponent } from './layout/layout.component';
import { LookupDataComponent } from './lookup-data/lookup-data.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then(
            (mod) => mod.DashboardModule
          ),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then(
            (mod) => mod.DashboardModule
          ),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./users/users.module').then(
            (mod) => mod.UsersModule
          ),
      },
      {
        path: 'organizations',
        loadChildren: () =>
          import('./organizations/organizations.module').then(
            (mod) => mod.OrganizationModule
          ),
      },
      {
        path: 'lookup-data',
        loadChildren: () =>
          import('./lookup-data/lookup-data.module').then(
            (mod) => mod.LookupDataModule
          ),
      },
      {
        path: 'pricing',
        loadChildren: () =>
          import('./prices/prices.module').then(
            (mod) => mod.PricesModule
          ),
      },
      {
        path: 'template',
        loadChildren: () =>
          import('./template/template.module').then(
            (mod) => mod.TemplateModule
          ),
      },
      {
        path: 'job-descriptions',
        loadChildren: () =>
          import('./job-descriptions/job-descriptions.module').then(
            (mod) => mod.JobDescriptionModule
          ),
      },
      {
        path: 'webinar',
        loadChildren: () =>
          import('./webinar/webinar.module').then(
            (mod) => mod.WebinarModule
          ),
      }
    ]
  },
  {
      path: 'login',
      loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [LookupEditCanDeactivateGuard]
})
export class AppRoutingModule { }
