import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { LookupDataComponent } from "../lookup-data/lookup-data.component";

@Injectable()
export class LookupEditCanDeactivateGuard implements CanDeactivate<LookupDataComponent> {
  
  constructor() { }

  canDeactivate(
    component: LookupDataComponent,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    
      let url: string = state.url;
      if(confirm('Please ensure all work has been saved')) {
      }
      return true;
  }
} 