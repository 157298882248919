import { Directive, Input, Renderer2, ElementRef, OnInit } from '@angular/core';
/**
 * This directive is used for styling the layout component 
 */
@Directive({
  selector: '[teneoLayout]'
})
export class LayoutDirective implements OnInit {
  // tslint:disable-next-line: no-inferrable-types
  /**
   * Header height directive
   */
  @Input() headerHeight: string = '45';
  /**
   * Fixed directive
   */
  @Input() fixed: string;
  constructor(private renderer: Renderer2, public hostElement: ElementRef) { }

  /**
   * Constructor for layout directives
   */
  ngOnInit() {
    if (this.headerHeight && this.fixed === 'true') {
      this.renderer.addClass(this.hostElement.nativeElement, 'pt-' + this.headerHeight);
    } else {

      this.renderer.removeClass(this.hostElement.nativeElement, 'pt-' + this.headerHeight);
    }
  }

}
