import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../layout.service';
import { NavService } from './nav-service';
import { animateText, onMainContentChange, onSideNavChange } from './animation';

@Component({
  selector: 'teneo-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  animations: [animateText, onMainContentChange, onSideNavChange]
})
export class SidenavComponent implements OnInit {
  nav: any[] = [];
  public linkText = false;
  nameText = 'null';
  name = 'null';
  surname = '';
  shortHand = '';

  public sideNavState = false;

  constructor(public ls: LayoutService, public navService: NavService) {
    this.ls.sideNavState$.subscribe(res => {
      this.sideNavState = res;
    });
  }

  ngOnInit() {
    this.nav = this.navService.getNavigation('navItems');
  }

  onSidenavToggle() {
    this.sideNavState = !this.sideNavState;
    this.ls.sideNavState$.next(this.sideNavState);
  }
  checkRoute(page,ac){
    // if(page.name === 'Lookup Data'){
    //   localStorage.setItem('navigated', JSON.stringify(true));
    // }
    // if(JSON.parse(localStorage.getItem('navigated'))  === true ){
    //   window.addEventListener('beforeunload', function (e) {
    //     // Cancel the event
    //     e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    //     // Chrome requires returnValue to be set
    //     e.returnValue = '';
    //   });
    // }
  }
}
