<!-- PASS LOGO AND BUTTON TYPE TO HEADER -->
<div [ngClass]="{'full-screen':ls.config.header.fixed}">
    <teneo-header #teneoHeader [fixed]='ls.config.header.fixed' [headerHeight]="ls.config.header.headerHeight"  [color]="ls.config.header.backgroundColor" [logo]="ls.config.header.logo" [buttonIcon]="ls.config.header.buttonIcon" (buttonClicked)="sideNavButtonClicked()">
    </teneo-header>

    <!-- SIDENAV DRAWER -->

    <mat-drawer-container class="full-screen">
        <mat-drawer #drawer teneoSidenav class="sideNavBar" mode="side" opened="true">
            <div teneoLayout [fixed]='ls.config.header.fixed' [headerHeight]="ls.config.header.headerHeight">
                <teneo-sidenav></teneo-sidenav>
            </div>
        </mat-drawer>

        <div class="pt-{{ls.config.header.headerHeight}}" [@onMainContentChange]="sideNavChange ? 'open': 'close'">
            <router-outlet>
            </router-outlet>
        </div>
    </mat-drawer-container>
</div>

